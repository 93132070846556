import type {
  CancelablePromise,
  TCreateClientRequest,
  TCreateClientResponse,
  TGetClientRequest,
  TGetClientResponse,
  TListClientAddressesResponse,
  TListClientNamesResponse,
  TListClientProgramsRequest,
  TListClientProgramsResponse,
  TListClientUsersRequest,
  TListClientUsersResponse,
  TListClientsRequest,
  TListClientsResponse,
  TUpdateClientRequest,
  TUpdateClientResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class ClientService {
  /**
   * List Clients
   * @returns Client[]
   * @throws ApiError
   */
  public static list(req?: TListClientsRequest): CancelablePromise<TListClientsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients',
      query: req,
    });
  }

  /**
   * List Client Addresses
   * @throws ApiError
   */
  public static listClientAddresses(): CancelablePromise<TListClientAddressesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/addresses',
    });
  }

  /**
   * List Client Programs
   * @returns Program[]
   * @throws ApiError
   */
  public static listClientPrograms({
    id,
    ...req
  }: TListClientProgramsRequest): CancelablePromise<TListClientProgramsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{id}/clientPrograms',
      path: { id },
      query: req,
    });
  }

  /**
   * List Client Users
   * @returns User[]
   * @throws ApiError
   */
  public static listClientUsers({ id, ...req }: TListClientUsersRequest): CancelablePromise<TListClientUsersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{id}/users',
      path: { id },
      query: req,
    });
  }

  /**
   * List Client Names
   * @returns ClientName
   * @throws ApiError
   */
  public static listClientNames(): CancelablePromise<TListClientNamesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/names',
    });
  }

  /**
   * Get Client
   * @param email string
   * @param password string
   * @returns Client
   * @throws ApiError
   */
  public static get({ id }: TGetClientRequest): CancelablePromise<TGetClientResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{id}',
      path: { id },
    });
  }

  /**
   * Create Client
   * @param client Client
   * @returns Client
   * @throws ApiError
   */
  public static create(req: TCreateClientRequest): CancelablePromise<TCreateClientResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients',
      body: req,
    });
  }

  /**
   * Update Client
   * @param client Client
   * @returns Client
   * @throws ApiError
   */
  public static update(req: TUpdateClientRequest): CancelablePromise<TUpdateClientResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/clients/{id}',
      path: { id: req.id },
      body: req,
    });
  }
}
