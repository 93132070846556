import { DropButton, Stack } from 'grommet';
import { LinkNext, Notification } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { AddButton, AuditLogIcon, Box, BoxProps, EntityIcon, Link, LoadingSpinner, Text } from '/src/components';
import { useModelAuditLogStore } from '/src/context';
import { ModelName } from '/src/lib/models';
import { tooltipMessages } from '/src/lib/tooltips';
import { AuditorType, ModelAuditLogState } from '/src/lib/types';
import { getModelLabel, pxToRem } from '/src/utils';

export const ModelAuditLogDropdown: React.FC<ModelAuditLogDropdownProps> = observer((props) => {
  const { ...boxProps } = props;
  const modelAuditLogStore = useModelAuditLogStore();

  const maxLogs = useRef(10);

  const [dropIsOpen, setDropIsOpen] = useState(false);

  const renderIcon = () => (
    <Stack anchor="top-right">
      <Box align="center" justify="center" width="24px" height="28px">
        <Notification size="20px" />
      </Box>
      {!!modelAuditLogStore.modelAuditLogs?.length && (
        <Box background="red" width="12px" height="12px" align="center" justify="center" round />
      )}
    </Stack>
  );

  return (
    <Box justify="center" {...boxProps}>
      <Box>
        <DropButton
          plain
          tip={tooltipMessages.logNotifications}
          icon={renderIcon()}
          open={dropIsOpen}
          reverse
          onOpen={() => {
            setDropIsOpen(true);
            modelAuditLogStore.fetchModelAuditLogs({
              action_taken_by: AuditorType.ExternalUser,
              model_audit_log_state_id: ModelAuditLogState.Unresolved,
            });
          }}
          onClose={() => {
            setDropIsOpen(false);
            modelAuditLogStore.reset();
          }}
          dropAlign={{ top: 'bottom', right: 'right' }}
          dropContent={
            <Box
              flex="grow"
              width={pxToRem(475)}
              border={{ size: 'small', color: 'light-2' }}
              height={!modelAuditLogStore.modelAuditLogs || modelAuditLogStore.isLoading ? '10rem' : undefined}
            >
              {(!modelAuditLogStore.modelAuditLogs || modelAuditLogStore.isLoading) && <LoadingSpinner />}
              {!!modelAuditLogStore.modelAuditLogs && !modelAuditLogStore.isLoading && (
                <Box>
                  <Box row pad={{ horizontal: 'small', vertical: '18px' }} gap="xsmall" align="center">
                    <AuditLogIcon size="18px" />
                    <Text size="18px">Unresolved Logs</Text>
                  </Box>
                  <Box
                    border={{
                      side: 'horizontal',
                      color: 'brand',
                      size: '2px',
                    }}
                    transitionProperty="height"
                    transitionDuration="0.3s"
                    transitionTimingFunction="ease"
                  >
                    {!!modelAuditLogStore.modelAuditLogs.length &&
                      modelAuditLogStore.modelAuditLogs.slice(0, maxLogs.current).map((modelAuditLog, i) => (
                        <Link
                          key={i}
                          to={`/change-logs/${modelAuditLog.id}`}
                          onClick={() => setDropIsOpen(false)}
                          color="accent-1"
                        >
                          <Box row align="center" gap="xsmall" pad="small" background={i % 2 ? 'white' : 'light-6'}>
                            <EntityIcon size="16" color="text" entityName={modelAuditLog.model_name} />
                            <Box row gap="4px" align="center">
                              <Box>
                                <Text truncate color="accent-1">
                                  {getModelLabel(modelAuditLog.model, modelAuditLog.model_name as ModelName)}
                                </Text>
                              </Box>
                              <Box flex="grow">
                                <Text>
                                  was {modelAuditLog.model_audit_log_event_type.name.toLowerCase()}d by{' '}
                                  {modelAuditLog.user.name}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Link>
                      ))}
                    {!modelAuditLogStore.modelAuditLogs.length && (
                      <Box pad={{ vertical: 'medium' }} background="light-6" justify="center">
                        <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                          No unresolved change logs found.
                        </Text>
                      </Box>
                    )}
                  </Box>
                  <Box pad="small" align="center">
                    <Link to={'/change-logs'} onClick={() => setDropIsOpen(false)}>
                      <AddButton
                        border={{ size: '0' }}
                        label="View all change logs"
                        icon={<LinkNext size="14px" />}
                        reverse
                      />
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          }
        />
      </Box>
    </Box>
  );
});

export type ModelAuditLogDropdownProps = BoxProps;
