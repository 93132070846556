import dayjs from 'dayjs';
import { useRef } from 'react';
import { Box, BoxProps, Text } from '/src/components';
import { Client, IncentiveStatement } from '/src/lib/models';
import { ReportingPeriodType } from '/src/lib/types';
import { getPeriodFromDateString, pxToPt } from '/src/utils';

export const IncentiveStatementInfoBar: React.FC<IncentiveStatementInfoBarProps> = (props) => {
  const { client, incentiveStatement, ...boxProps } = props;

  const lineHeight = useRef(pxToPt('24px'));
  const labelSize = useRef(pxToPt('14px'));
  const valueSize = useRef(pxToPt('12px'));
  const subValueSize = useRef(pxToPt('10px'));

  return (
    <Box direction="row" justify="between" {...boxProps}>
      <Box direction="row" width="50%">
        <Box width="50%">
          <Text size={labelSize.current} lineHeight={lineHeight.current} weight={700}>
            Client Name
          </Text>
          <Text size={valueSize.current} lineHeight={lineHeight.current} wordBreak="break-word">
            {client.name}
          </Text>
        </Box>
        <Box>
          <Text size={labelSize.current} lineHeight={lineHeight.current} weight={700}>
            Reporting Period
          </Text>
          <Text size={valueSize.current} lineHeight={lineHeight.current} wordBreak="break-word">
            {getPeriodFromDateString(
              incentiveStatement.clientReportingPeriod?.start_reporting_quarter,
              incentiveStatement.clientReportingPeriod?.reporting_period_type_id === ReportingPeriodType.Yearly
            )}
          </Text>
        </Box>
      </Box>
      <Box direction="row" width="50%">
        <Box width="50%">
          <Text size={labelSize.current} lineHeight={lineHeight.current} weight={700}>
            Statement Date
          </Text>
          <Text size={valueSize.current} lineHeight={lineHeight.current} wordBreak="break-word">
            {dayjs(incentiveStatement.statement_date).format('MM/DD/YYYY')}
          </Text>
        </Box>
        <Box>
          <Text size={labelSize.current} lineHeight={lineHeight.current} weight={700}>
            Remittance Method
          </Text>
          {!!incentiveStatement.remittance_method?.name && (
            <Box direction="row" gap={pxToPt('3px')}>
              <Text size={valueSize.current} lineHeight={lineHeight.current}>
                {incentiveStatement.remittance_method?.name}
              </Text>
              {incentiveStatement.remittance_method?.name?.toLowerCase().includes('ach') && (
                <Text size={subValueSize.current} lineHeight={lineHeight.current}>
                  <i>(Account ending in {client.account_number?.slice(client.account_number.length - 4)})</i>
                </Text>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export type IncentiveStatementInfoBarProps = BoxProps & {
  client: Client;
  incentiveStatement: IncentiveStatement;
};
