import dayjs from 'dayjs';
import { Button, CheckBox, Layer } from 'grommet';
import { Close, StatusWarning } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LastActivePeriodSelect } from '../forms/LastActivePeriodSelect';
import { ApiError, EquipmentService } from '/src/api';
import { EntityIcon, FormCard, InfoBox, Text } from '/src/components';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { Equipment, ModelName } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString, FormErrors, FormMetadata, SelectOptions } from '/src/lib/types';
import { getFormErrors, getModelLabel, getPeriodFromDateString, sortPeriodOptions } from '/src/utils';

export type RetireEquipmentModalProps = {
  equipment: Equipment;
  setIsVisible: (isVisible: boolean) => void;
  setShouldRefresh: (shouldRefresh: boolean) => void;
  isCanada?: boolean;
};

export type TRetireEquipmentModalForm = {
  last_active_reporting_quarter: DateString;
};

export const RetireEquipmentModal: React.FC<RetireEquipmentModalProps> = observer(
  ({ equipment, setIsVisible, setShouldRefresh, isCanada = false }) => {
    /** Context **/
    const userStore = useUserStore();
    const globalStore = useGlobalStore();
    const clientStore = useClientStore();

    /** State **/
    const [isLoading, setIsLoading] = useState(false);
    const [isRetiring, setIsRetiring] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const [reportingPeriodOptions, setReportingPeriodOptions] = useState<SelectOptions<DateString>>();
    const [formValues, setFormValues] = useState<TRetireEquipmentModalForm>({
      last_active_reporting_quarter: '',
    });
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    /** Computed **/
    const canRetire =
      userStore.isClientAdmin || userStore.isFacilityAdmin
        ? !!userStore.user?.facilities.find((f) => f.id === equipment.facility_id)
        : userStore.isFuseAdmin;

    const selectedPeriodLabel = getPeriodFromDateString(formValues.last_active_reporting_quarter ?? '', isCanada);

    /** Methods **/
    const updateFormValue = (key: string, value: DateString) => {
      setFormValues({
        ...formValues,
        [key]: value,
      });
      if (formErrors[key]) formErrors[key] = '';
    };

    const loadModalData = async () => {
      if (!equipment.client_id || !equipment.reporting_period_type_id) return;

      try {
        setIsLoading(true);

        const reportingPeriods = await clientStore.fetchClientReportingPeriods(
          equipment.client_id,
          equipment.reporting_period_type_id
        );

        const options: SelectOptions<DateString> = reportingPeriods
          .filter((p) => !dayjs(p.start_reporting_quarter).isBefore(equipment.first_active_reporting_quarter))
          .map((p) => ({
            label: getPeriodFromDateString(p.start_reporting_quarter, isCanada),
            value: p.start_reporting_quarter,
          }));

        setReportingPeriodOptions(sortPeriodOptions(options));
      } catch (err) {
        globalStore.handleApiError(err as ApiError, toastMessages.listClientReportingPeriods.error);
      } finally {
        setIsLoading(false);
      }
    };

    const retireEquipment = async () => {
      if (isRetiring) return;

      const formFields: FormMetadata = {
        last_active_reporting_quarter: {
          label: 'Last Reporting Period',
          required: true,
        },
      };

      const errors = getFormErrors(formFields, formValues);
      if (errors) {
        setFormErrors(errors);
        return;
      }

      try {
        setIsRetiring(true);
        await EquipmentService.retire({
          id: equipment.id,
          last_active_reporting_quarter: formValues.last_active_reporting_quarter ?? '',
        });
        setShouldRefresh(true);
        setIsVisible(false);
        toast.success(toastMessages.retireEquipment.success);
      } catch (err) {
        globalStore.handleApiError(err as ApiError, toastMessages.retireEquipment.error);
      } finally {
        setIsRetiring(false);
      }
    };

    /** Effects **/
    useEffect(() => {
      if (!reportingPeriodOptions) {
        loadModalData();
      }
    }, [reportingPeriodOptions]);

    useEffect(() => {
      if (!canRetire) setIsVisible(false);
    }, [canRetire]);

    /** Render **/
    return (
      <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
        <FormCard
          width="30rem"
          title={`Retire ${getModelLabel(equipment, ModelName.Equipment)}`}
          icon={<EntityIcon entityName={ModelName.Equipment} />}
          onSubmit={() => retireEquipment()}
          isLoading={isLoading || isRetiring}
          hideRequiredText
          saveButtonLabel="RETIRE"
          saveButtonLoadingLabel="RETIRING..."
          saveButtonDisabled={!isConfirmed}
          headerChildren={[
            <Button
              key="close"
              pad="none"
              onClick={() => setIsVisible(false)}
              icon={<Close size="20px" />}
              tip="Close"
            />,
          ]}
        >
          <InfoBox gap="1rem">
            <StatusWarning />
            <Text fontFamily="Lato, sans-serif">
              Warning: Retiring this Equipment will discard any Usage data <b>after</b>{' '}
              {formValues.last_active_reporting_quarter ? selectedPeriodLabel : 'the last reporting period'}.
            </Text>
          </InfoBox>
          <LastActivePeriodSelect
            id="last_active_reporting_quarter"
            label="Last Reporting Period"
            placeholder={isLoading ? 'Loading...' : 'Select Period...'}
            value={formValues.last_active_reporting_quarter}
            setValue={(value) => updateFormValue('last_active_reporting_quarter', value)}
            clientId={equipment.client_id}
            reportingPeriodTypeId={equipment.reporting_period_type_id}
            firstActivePeriod={equipment.first_active_reporting_quarter}
            error={formErrors['last_active_reporting_quarter']}
            required
            fill="horizontal"
          />
          {formValues.last_active_reporting_quarter && (
            <InfoBox gap="1rem" background={{ color: 'status-error', dark: true }}>
              <CheckBox checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)} />
              <Text fontFamily="Lato, sans-serif" color="white">
                I understand that all usage data <b>after</b> {selectedPeriodLabel} will be discarded.
              </Text>
            </InfoBox>
          )}
        </FormCard>
      </Layer>
    );
  }
);
