import { Float, TCredentialsType, TNodeEnv } from '/src/lib/types';

const getEnvironmentVariable = (unvalidatedEnvironmentVariable: string | undefined, defaultValue?: string): string => {
  if (!unvalidatedEnvironmentVariable) {
    if (defaultValue) return defaultValue;
    throw new Error(`Couldn't find environment variable: ${unvalidatedEnvironmentVariable}`);
  } else {
    return unvalidatedEnvironmentVariable;
  }
};

export interface IConfig {
  nodeEnv: TNodeEnv;
  client: {
    url: string;
  };
  api: {
    url: string;
    version: string;
    useCredentials: boolean;
    credentialsType: TCredentialsType;
  };
  logs: {
    rootNamespace: string;
  };
  urls: {
    fuseWebsiteUrl: string;
    fuseSupportUrl: string;
    privacyPolicyUrl: string;
    apiDataUrl: string;
  };
  maps: {
    nominatimUrl: string;
  };
  sentry: {
    dsn: string;
    tracesSampleRate: Float;
    replaysSessionSampleRate: Float;
    replaysOnErrorSampleRate: Float;
  };
}

export const config: IConfig = {
  nodeEnv: getEnvironmentVariable(process.env.NODE_ENV) as TNodeEnv,
  client: {
    url: getEnvironmentVariable(process.env.CLIENT_URL, 'http://localhost:1234'),
  },
  api: {
    url: getEnvironmentVariable(process.env.API_URL),
    version: getEnvironmentVariable(process.env.API_VERSION),
    useCredentials: getEnvironmentVariable(process.env.API_USE_CREDENTIALS, 'false') === 'true',
    credentialsType: getEnvironmentVariable(process.env.API_CREDENTIALS_TYPE, 'include') as TCredentialsType,
  },
  logs: {
    rootNamespace: getEnvironmentVariable(process.env.LOGS_ROOT_NAMESPACE, 'fuse-frontend'),
  },
  urls: {
    fuseWebsiteUrl: getEnvironmentVariable(process.env.FUSE_WEBSITE_URL),
    fuseSupportUrl: getEnvironmentVariable(process.env.FUSE_SUPPORT_URL),
    privacyPolicyUrl: getEnvironmentVariable(process.env.PRIVACY_POLICY_URL),
    apiDataUrl: getEnvironmentVariable(process.env.API_DATA_URL),
  },
  maps: {
    nominatimUrl: getEnvironmentVariable(process.env.OPEN_STREET_MAPS_NOMINATIM_URL),
  },
  sentry: {
    dsn: getEnvironmentVariable(process.env.SENTRY_DSN_URL),
    tracesSampleRate: parseFloat(getEnvironmentVariable(process.env.TRACES_SAMPLE_RATE, '1.0')),
    replaysSessionSampleRate: parseFloat(getEnvironmentVariable(process.env.REPLAYS_SESSION_SAMPLE_RATE, '0.1')),
    replaysOnErrorSampleRate: parseFloat(getEnvironmentVariable(process.env.REPLAYS_ON_ERROR_SAMPLE_RATE, '1.0')),
  },
};
