import {
  Client,
  Equipment,
  EquipmentUsage,
  Facility,
  TClientId,
  TEquipmentId,
  TEquipmentUsageId,
  TFacilityId,
  TUserId,
  User,
} from '/src/lib/models';

export type TModelId = TClientId | TFacilityId | TEquipmentId | TEquipmentUsageId | TUserId;

export enum ModelName {
  Client = 'Client',
  Facility = 'Facility',
  Equipment = 'Equipment',
  EquipmentUsage = 'EquipmentUsage',
  User = 'User',
}

export type Model = Client | Facility | Equipment | EquipmentUsage | User;
