import dayjs from 'dayjs';
import { BasicSelectProps, SelectExtendedProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ApiError } from '/src/api';
import { BoxProps, Select } from '/src/components';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { TClientId, TReportingPeriodTypeId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString, ReportingPeriodType, SelectOptions } from '/src/lib/types';
import { getPeriodFromDateString, sortPeriodOptions } from '/src/utils';

export type FirstActivePeriodSelectProps = {
  value: DateString;
  setValue: (reportingPeriod: DateString) => void;
  clientId?: TClientId;
  reportingPeriodTypeId?: TReportingPeriodTypeId;
  firstActivePeriod?: DateString;
  error?: string;
  hideOptionalText?: boolean;
  hideLabel?: boolean;
  label?: string;
  id?: string;
  name?: string;
  disabled?: BasicSelectProps['disabled'];
  placeholder?: string;
  required?: boolean;
  fill?: BoxProps['fill'];
  width?: BoxProps['width'];
  style?: SelectExtendedProps['style'];
};

export const FirstActivePeriodSelect: React.FC<FirstActivePeriodSelectProps> = observer((props) => {
  const {
    value,
    setValue,
    clientId,
    reportingPeriodTypeId,
    firstActivePeriod,
    id,
    name,
    label,
    disabled,
    placeholder,
    ...selectProps
  } = props;

  /** Context **/
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const clientStore = useClientStore();

  /** State **/
  const [options, setOptions] = useState<SelectOptions<DateString>>();

  /** Computed **/
  const isLoading = !options;

  /** Methods **/
  const fetchOptions = async (
    clientId: TClientId,
    reportingPeriodTypeId: TReportingPeriodTypeId,
    firstActivePeriod: DateString
  ) => {
    try {
      /*
        Rules - First Reporting Period

        1. Reporting periods should only be the client's unfinalized reporting periods
        2. Do not allow client users to add facilities/equipment with a locked first reporting period
      */

      const reportingPeriods = await clientStore.fetchClientReportingPeriods(
        clientId,
        reportingPeriodTypeId,
        false, // hide finalized
        userStore.isExternalUser // hide locked if external
      );

      const options = reportingPeriods
        .filter((p) => !dayjs(p.start_reporting_quarter).isBefore(firstActivePeriod))
        .map((p) => ({
          label: getPeriodFromDateString(
            p.start_reporting_quarter,
            reportingPeriodTypeId === ReportingPeriodType.Yearly
          ),
          value: p.start_reporting_quarter,
        }));

      setOptions(sortPeriodOptions(options));
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listClientReportingPeriods.error);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (clientId && reportingPeriodTypeId && firstActivePeriod && !options) {
      fetchOptions(clientId, reportingPeriodTypeId, firstActivePeriod);
    }
  }, [clientId, reportingPeriodTypeId, firstActivePeriod, options]);

  return (
    <Select
      value={value}
      setValue={(value) => setValue(value)}
      options={options ?? []}
      name={id ? undefined : name || 'reporting_period_select'}
      label={label ?? 'Reporting Period'}
      placeholder={isLoading ? 'Loading...' : placeholder}
      disabled={isLoading || disabled}
      {...selectProps}
    />
  );
});
