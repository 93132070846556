import { Button, Layer } from 'grommet';
import { Close, StatusWarning } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, EquipmentService } from '/src/api';
import { EntityIcon, FormCard, InfoBox, Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Equipment, ModelName } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { getModelLabel } from '/src/utils';

export type DeleteEquipmentModalProps = {
  equipment: Equipment;
  setIsVisible: (isVisible: boolean) => void;
};

export const DeleteEquipmentModal: React.FC<DeleteEquipmentModalProps> = observer(({ equipment, setIsVisible }) => {
  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const navigate = useNavigate();

  /** State **/
  const [isDeleting, setIsDeleting] = useState(false);

  /** Computed **/
  const isLoading = !equipment;
  const canDelete = userStore.isFuseAdmin;

  /** Methods **/
  const deleteEquipment = async () => {
    if (isDeleting) return;
    try {
      setIsDeleting(true);
      await EquipmentService.delete({ id: equipment.id });
      setIsVisible(false);
      toast.success(toastMessages.deleteEquipment.success);
      navigate(`/clients/${equipment.client_id}/facilities/${equipment.facility_id}/equipment`);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.deleteEquipment.error);
    } finally {
      setIsDeleting(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (!canDelete) setIsVisible(false);
  }, [canDelete]);

  /** Render **/
  return (
    <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
      <FormCard
        width="30rem"
        title={`Delete ${getModelLabel(equipment, ModelName.Equipment)}`}
        icon={<EntityIcon entityName={ModelName.Equipment} />}
        onSubmit={() => deleteEquipment()}
        isLoading={isLoading || isDeleting}
        hideRequiredText
        saveButtonLabel="DELETE"
        saveButtonLoadingLabel="DELETING..."
        headerChildren={[
          <Button
            key="close"
            pad="none"
            onClick={() => setIsVisible(false)}
            icon={<Close size="20px" />}
            tip="Close"
          />,
        ]}
      >
        <InfoBox gap="1rem">
          <StatusWarning />
          <Text fontFamily="Lato, sans-serif">Are you sure you want to delete this Equipment?</Text>
        </InfoBox>
      </FormCard>
    </Layer>
  );
});
