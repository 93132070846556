import dayjs from 'dayjs';
import { BasicSelectProps, SelectExtendedProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ApiError } from '/src/api';
import { BoxProps, Select } from '/src/components';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { ClientReportingPeriod, TClientId, TReportingPeriodTypeId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString, ReportingPeriodType, SelectOptions } from '/src/lib/types';
import { getPeriodFromDateString, sortPeriodOptions, sortPeriods } from '/src/utils';

export type LastActivePeriodSelectProps = {
  value: DateString;
  setValue: (reportingPeriod: DateString) => void;
  clientId?: TClientId;
  reportingPeriodTypeId?: TReportingPeriodTypeId;
  firstActivePeriod?: DateString;
  error?: string;
  hideOptionalText?: boolean;
  hideLabel?: boolean;
  label?: string;
  id?: string;
  name?: string;
  disabled?: BasicSelectProps['disabled'];
  placeholder?: string;
  required?: boolean;
  fill?: BoxProps['fill'];
  width?: BoxProps['width'];
  style?: SelectExtendedProps['style'];
};

export const LastActivePeriodSelect: React.FC<LastActivePeriodSelectProps> = observer((props) => {
  const {
    value,
    setValue,
    clientId,
    reportingPeriodTypeId,
    firstActivePeriod,
    id,
    name,
    label,
    disabled,
    placeholder,
    ...selectProps
  } = props;

  /** Context **/
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const clientStore = useClientStore();

  /** State **/
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<SelectOptions<DateString>>();

  /** Methods **/
  const fetchOptions = async (
    clientId: TClientId,
    reportingPeriodTypeId: TReportingPeriodTypeId,
    firstActivePeriod: DateString
  ) => {
    try {
      setIsLoading(true);

      /*
        Rules - Retirement (Last Reporting Period)

        1. Don't display any periods before the facility/equipment's first active period. 
        2. Don't display any finalized periods EXCEPT the most recent one. 
        3. Don't display anything before the locked period. 
      */

      const reportingPeriods = sortPeriods(
        await clientStore.fetchClientReportingPeriods(clientId, reportingPeriodTypeId, true, false)
      );

      let mostRecentFinalized: ClientReportingPeriod | undefined;
      let mostRecentLocked: ClientReportingPeriod | undefined;

      for (const p of reportingPeriods) {
        if (p.is_finalized && !mostRecentFinalized) mostRecentFinalized = p;
        if (p.is_client_locked && !mostRecentLocked) mostRecentLocked = p;
        if (mostRecentFinalized && mostRecentLocked) break;
      }

      const options = reportingPeriods
        .filter((p) => {
          const reportingPeriodDate = dayjs(p.start_reporting_quarter);

          // Don't display any periods before the facility/equipment's first active period.
          if (reportingPeriodDate.isBefore(firstActivePeriod)) return false;

          // Don't display any finalized periods EXCEPT the most recent one.
          if (p.is_finalized && p.id !== mostRecentFinalized?.id) return false;

          // Don't display anything before the locked period.
          if (
            userStore.isExternalUser &&
            mostRecentLocked &&
            reportingPeriodDate.isBefore(mostRecentLocked.start_reporting_quarter)
          )
            return false;

          return true;
        })
        .map((p) => ({
          label: getPeriodFromDateString(
            p.start_reporting_quarter,
            reportingPeriodTypeId === ReportingPeriodType.Yearly
          ),
          value: p.start_reporting_quarter,
        }));

      setOptions(sortPeriodOptions(options));
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listClientReportingPeriods.error);
    } finally {
      setIsLoading(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (clientId && reportingPeriodTypeId && firstActivePeriod && !options) {
      fetchOptions(clientId, reportingPeriodTypeId, firstActivePeriod);
    }
  }, [clientId, reportingPeriodTypeId, firstActivePeriod, options]);

  return (
    <Select
      value={value}
      setValue={(value) => setValue(value)}
      options={options ?? []}
      name={id ? undefined : name || 'reporting_period_select'}
      label={label ?? 'Reporting Period'}
      placeholder={isLoading ? 'Loading...' : placeholder}
      disabled={isLoading || disabled}
      {...selectProps}
    />
  );
});
