import type {
  CancelablePromise,
  TCreateFacilityRequest,
  TCreateFacilityResponse,
  TDeleteFacilityRequest,
  TGetFacilityRequest,
  TGetFacilityResponse,
  TListAllFacilitiesRequest,
  TListAllFacilitiesResponse,
  TListFacilitiesRequest,
  TListFacilitiesResponse,
  TListFacilityAddressesResponse,
  TRetireFacilityRequest,
  TUpdateFinalizedFacilityRequest,
  TUpdateFinalizedFacilityResponse,
  TUpdateUnfinalizedFacilityRequest,
  TUpdateUnfinalizedFacilityResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class FacilityService {
  /**
   * List Facilities
   * @returns Facility Facility
   * @throws ApiError
   */
  public static list({ client_id, ...req }: TListFacilitiesRequest): CancelablePromise<TListFacilitiesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{client_id}/facilities',
      path: { client_id },
      query: req,
    });
  }

  /**
   * List Facility Addresses
   * @throws ApiError
   */
  public static listFacilityAddresses(): CancelablePromise<TListFacilityAddressesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/facilities/addresses',
    });
  }

  /**
   * List All Facilities
   * @returns Facility Facility
   * @throws ApiError
   */
  public static listAll(req?: TListAllFacilitiesRequest): CancelablePromise<TListAllFacilitiesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/facilities',
      query: req,
    });
  }

  /**
   * Get Facility
   * @returns Facility Facility
   * @throws ApiError
   */
  public static get({ id }: TGetFacilityRequest): CancelablePromise<TGetFacilityResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/facilities/{id}',
      path: { id },
    });
  }

  /**
   * Create Facility
   * @returns Facility Facility
   * @throws ApiError
   */
  public static create({ client_id, ...req }: TCreateFacilityRequest): CancelablePromise<TCreateFacilityResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{client_id}/facilities',
      path: { client_id },
      body: req,
    });
  }

  /**
   * Update Unfinalized Facility
   * @returns Facility Facility
   * @throws ApiError
   */
  public static updateUnfinalized({
    id,
    ...req
  }: TUpdateUnfinalizedFacilityRequest): CancelablePromise<TUpdateUnfinalizedFacilityResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/facilities/{id}/notFinalized',
      path: { id },
      body: req,
    });
  }

  /**
   * Update Finalized Facility
   * @returns Facility Facility
   * @throws ApiError
   */
  public static updateFinalized({
    id,
    ...req
  }: TUpdateFinalizedFacilityRequest): CancelablePromise<TUpdateFinalizedFacilityResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/facilities/{id}/finalized',
      path: { id },
      body: req,
    });
  }

  /**
   * Retire Facility
   * @throws ApiError
   */
  public static retire({ id, ...req }: TRetireFacilityRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/facilities/{id}/retire',
      path: { id },
      body: req,
    });
  }

  /**
   * Delete Facility
   * @throws ApiError
   */
  public static delete({ id }: TDeleteFacilityRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/facilities/{id}',
      path: { id },
    });
  }
}
