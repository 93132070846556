import { Button } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Select, Text } from '/src/components';
import { ClientReportingPeriod, TClientReportingPeriodId } from '/src/lib/models';
import { SelectOptions } from '/src/lib/types';
import { getPeriodFromDateString, sortPeriods } from '/src/utils';

export type ReportingPeriodDropdownProps = {
  reportingPeriods: ClientReportingPeriod[];
  setSelectedReportingPeriod: (selectedPeriod: ClientReportingPeriod) => void;
  selectedReportingPeriod?: ClientReportingPeriod;
  nextPeriod?: ClientReportingPeriod;
  previousPeriod?: ClientReportingPeriod;
  isCanada?: boolean;
  showNav?: boolean;
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  fill?: BoxProps['fill'];
};

export const ReportingPeriodDropdown: React.FC<ReportingPeriodDropdownProps> = observer((props) => {
  const {
    reportingPeriods,
    selectedReportingPeriod,
    setSelectedReportingPeriod,
    nextPeriod,
    previousPeriod,
    isCanada,
    id,
    name,
    showNav,
    error,
    fill,
    ...selectProps
  } = props;

  /** State **/
  const [options, setOptions] = useState<SelectOptions<TClientReportingPeriodId>>();

  /** Effects **/
  useEffect(() => {
    const periodOptions = sortPeriods(reportingPeriods).map((period) => ({
      label: getPeriodFromDateString(period.end_reporting_quarter, !!isCanada),
      value: period.id,
    }));

    setOptions(periodOptions);
  }, [reportingPeriods, isCanada]);

  /** Render **/
  return (
    <Box>
      <Box direction="row" gap="small">
        {showNav && (
          <Button
            plain
            icon={<Previous />}
            onClick={() => {
              if (previousPeriod) setSelectedReportingPeriod(previousPeriod);
            }}
            disabled={!previousPeriod}
            tip="Previous Period"
          />
        )}
        <Select
          id={id}
          name={id ? undefined : name || 'reporting_period'}
          label={selectProps.label || 'Reporting Period'}
          placeholder={selectProps.placeholder || 'Choose...'}
          value={selectedReportingPeriod?.id}
          setValue={(value) => {
            const period = reportingPeriods?.find((p) => p.id === value);
            if (period) setSelectedReportingPeriod(period);
          }}
          options={options ?? []}
          disabled={selectProps.disabled}
          required={selectProps.required}
          fill={fill}
        />
        {showNav && (
          <Button
            plain
            icon={<Next />}
            onClick={() => {
              if (nextPeriod) setSelectedReportingPeriod(nextPeriod);
            }}
            disabled={!nextPeriod}
            tip="Next Period"
          />
        )}
      </Box>
      {error && (
        <Box row gap="small" margin={{ top: '0.5rem', left: '0.75rem' }}>
          <Text color="red" size="0.75rem">
            {error}
          </Text>
        </Box>
      )}
    </Box>
  );
});
