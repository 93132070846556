import { Button, CardBody, Layer } from 'grommet';
import { CircleAlert, Close } from 'grommet-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, CardHeader, Text } from '/src/components';

export type InactiveUserModalProps = {
  setIsVisible: (isVisible: boolean) => void;
};

export const InactiveUserModal: React.FC<InactiveUserModalProps> = (props) => {
  /* Props */
  const { setIsVisible } = props;

  /* Render */
  return (
    <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
      <Card width="30rem" pad="none" margin="none" style={{ overflow: 'hidden' }}>
        <CardHeader direction="row">
          <CircleAlert />
          <Text size="large">Inactive User</Text>
          <Box flex="grow" />
          <Button key="close" pad="none" onClick={() => setIsVisible(false)} icon={<Close size="18px" />} tip="Close" />
        </CardHeader>
        <CardBody pad={{ vertical: '2rem', horizontal: 'medium' }} direction="row">
          <Text size="1.1rem" lineHeight="medium" fontFamily="Lato, sans-serif">
            Contact{' '}
            <Link style={{ textDecoration: 'none' }} to="mailto:support@usefuse.com">
              support@usefuse.com
            </Link>{' '}
            to activate your account.
          </Text>
        </CardBody>
      </Card>
    </Layer>
  );
};
