import dayjs from 'dayjs';
import { BasicSelectProps, SelectExtendedProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ApiError } from '/src/api';
import { BoxProps, Select } from '/src/components';
import { useClientStore, useGlobalStore } from '/src/context';
import { Client } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString, ReportingPeriodType, SelectOptions } from '/src/lib/types';
import { getPeriodFromDateString, sortPeriodOptions } from '/src/utils';

export type ReportingPeriodSelectProps = {
  value: DateString;
  setValue: (reportingPeriod: DateString) => void;
  client?: Client;
  options?: SelectOptions<DateString>;
  hideFinalized?: boolean;
  hideLocked?: boolean;
  error?: string;
  hideOptionalText?: boolean;
  hideLabel?: boolean;
  label?: string;
  id?: string;
  name?: string;
  disabled?: BasicSelectProps['disabled'];
  placeholder?: string;
  required?: boolean;
  fill?: BoxProps['fill'];
  width?: BoxProps['width'];
  style?: SelectExtendedProps['style'];
};

export const ReportingPeriodSelect: React.FC<ReportingPeriodSelectProps> = observer((props) => {
  const { value, setValue, client, options, hideFinalized, hideLocked, id, name, label, ...selectProps } = props;

  /** Context **/
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();

  /** State **/
  const [, setIsLoading] = useState(false);
  const [reportingPeriodOptions, setReportingPeriodOptions] = useState<SelectOptions<DateString>>();

  /** Methods **/
  const fetchOptions = async () => {
    if (options) {
      setReportingPeriodOptions(options);
      return;
    } else if (!client) {
      console.warn('Client must be provided to ReportingPeriodSelect when options prop is undefined');
      return;
    }

    try {
      setIsLoading(true);

      const reportingPeriods = await clientStore.fetchClientReportingPeriods(
        client.id,
        client.reporting_period_type_id,
        !hideFinalized,
        hideLocked
      );

      /*
        RULES
  
        - Create/Update (First Reporting Period)
          - Reporting periods should only be the client's unfinalized reporting periods
          - Do not allow client users to add facilities/equipment with a locked first reporting period
        - Retire (Last Reporting Period)
          - Don't display any periods before the facility/equipment's first active period. Obvious because you can't retire it before it existed.
          - Don't display any finalized periods EXCEPT the most recent one. They can choose the most recent finalized period because that could have been the last period the equipment was used in.
          - Don't display anything before the locked period. They can still retire in the locked period because that will only affect the period AFTER .
        */

      setReportingPeriodOptions(
        sortPeriodOptions(
          reportingPeriods
            .filter((p) => !dayjs(p.start_reporting_quarter).isBefore(client.first_active_reporting_quarter))
            .map((p) => ({
              label: getPeriodFromDateString(
                p.start_reporting_quarter,
                client.reporting_period_type_id === ReportingPeriodType.Yearly
              ),
              value: p.start_reporting_quarter,
            }))
        )
      );
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listClientReportingPeriods.error);
    } finally {
      setIsLoading(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (!reportingPeriodOptions) {
      fetchOptions();
    }
  }, [reportingPeriodOptions]);

  return (
    <Select
      value={value}
      setValue={(value) => setValue(value)}
      options={reportingPeriodOptions ?? []}
      name={id ? undefined : name || 'reporting_period_select'}
      label={label ?? 'Reporting Period'}
      {...selectProps}
    />
  );
});
