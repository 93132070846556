import dayjs from 'dayjs';
import { ResponsiveContext } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ApiErrorItem, ClientService, TCreateClientRequest } from '/src/api';
import {
  Box,
  BreadcrumbNav,
  ClientCommissions,
  ClientCorporateInformation,
  ClientGeneralInformation,
  ClientRemittanceInformation,
  FormCard,
  FormPage,
  Line,
} from '/src/components';
import { useGlobalStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';
import { ClientDataForm, CountryId, DateString, FormErrors, FormMetadata } from '/src/lib/types';
import { capitalize, getFormErrors, getIsMobile } from '/src/utils';

export const CreateClientPage = observer(() => {
  /* Context */
  const globalStore = useGlobalStore();
  const navigate = useNavigate();
  const screenSize = useContext(ResponsiveContext);

  /* Refs */
  const defaultFormData = useRef<ClientDataForm>({
    name: '',
    website: '',
    business_id: '',
    start_date: '',
    first_active_reporting_quarter: '',
    remittance_method_id: NaN,
    bank_name: '',
    bank_account_type_id: NaN,
    routing_number: '',
    account_number: '',
    jumpstart_amount: '',
    comments: '',
    hq_address_line1: '',
    hq_address_line2: '',
    hq_address_city: '',
    hq_address_region_id: NaN,
    hq_address_post_code: '',
    remittance_address_line1: '',
    remittance_address_line2: '',
    remittance_address_city: '',
    remittance_address_region_id: NaN,
    remittance_address_post_code: '',
    programs: [],
  });

  /* State */
  const [isLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  // Form Values
  const [formValues, setFormValues] = useState<ClientDataForm>(defaultFormData.current);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [commissionErrors, setCommissionErrors] = useState<Record<string, FormErrors>>({});

  /* Computed */
  const isMobile = getIsMobile(screenSize);
  const isCanada = globalStore.getRegionById(formValues.hq_address_region_id)?.country_id === CountryId.Canada;

  /* Methods */
  const updateFormValue = (key: string, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  const validateForm = (formValues: ClientDataForm, shouldSetErrors = true) => {
    if (!formValues) return false;

    const nextPeriodStartDate: DateString = dayjs(formValues.first_active_reporting_quarter)
      .add(isCanada ? 24 : 6, 'months')
      .format('YYYY-MM-DD');

    const formFields: FormMetadata = {
      name: { label: 'Name', max: 100, required: true },
      website: { label: 'Website', max: 255 },
      business_id: { label: 'FEIN or Business Number', required: true, max: 20 },
      start_date: {
        label: 'Start date',
        required: true,
        sameOrAfter: formValues.first_active_reporting_quarter,
        before: nextPeriodStartDate,
      },
      first_active_reporting_quarter: { label: 'First Reporting Period', required: true },
      remittance_method_id: { label: 'Remittance method', required: true },
      comments: { label: 'Comments', max: 100 },
      hq_address_line1: { label: 'Address', max: 100, required: true },
      hq_address_line2: { label: 'Address', max: 100 },
      hq_address_city: { label: 'City', max: 50, required: true },
      hq_address_region_id: { label: 'Region', required: true },
      hq_address_post_code: { label: 'Postal code', max: 20, required: true },
      bank_account_type_id: { label: 'Bank account type', required: formValues.remittance_method_id === 1 },
      bank_name: { label: 'Bank name', max: 50, required: formValues.remittance_method_id === 1 },
      routing_number: { label: 'Routing number', max: 20, required: formValues.remittance_method_id === 1 },
      account_number: { label: 'Account number', max: 30, required: formValues.remittance_method_id === 1 },
      remittance_address_line1: { label: 'Address', max: 100, required: formValues.remittance_method_id === 2 },
      remittance_address_line2: { label: 'Address', max: 100 },
      remittance_address_city: { label: 'City', max: 50, required: formValues.remittance_method_id === 2 },
      remittance_address_region_id: { label: 'Region', max: 50, required: formValues.remittance_method_id === 2 },
      remittance_address_post_code: {
        label: 'Postal code',
        max: 20,
        required: formValues.remittance_method_id === 2,
      },
    };

    const errors = getFormErrors(formFields, formValues);

    const programErrors: Record<string, FormErrors> = {};
    let hasProgramErrors = false;

    formValues.programs.forEach((programData, i) => {
      const dataErrors = getFormErrors(
        {
          program_id: { label: 'Program', required: true },
          rate: { label: 'Commission rate', required: true, maxValue: programData.is_percent_rate ? 99.99 : 1 },
        },
        programData
      );

      if (dataErrors) {
        programErrors[i] = dataErrors;
        hasProgramErrors = true;
      }
    });

    if (shouldSetErrors) {
      if (errors) setFormErrors(errors);
      if (hasProgramErrors) setCommissionErrors(programErrors);
    }

    return !errors;
  };

  const createClient = async () => {
    if (!validateForm(formValues)) return;

    const clientWebsite = formValues.website
      ? formValues.website.split('://').length === 1
        ? `https://${formValues.website}`
        : formValues.website
      : null;

    const newClient: TCreateClientRequest = {
      name: formValues.name,
      website: clientWebsite,
      business_id: formValues.business_id,
      start_date: formValues.start_date,
      first_active_reporting_quarter: formValues.first_active_reporting_quarter,
      remittance_method_id: formValues.remittance_method_id,
      bank_name: formValues.bank_name || null,
      bank_account_type_id: formValues.bank_account_type_id || null,
      routing_number: formValues.routing_number || null,
      account_number: formValues.account_number || null,
      jumpstart_amount: null,
      comments: formValues.comments || null,
      hq_address_line1: formValues.hq_address_line1,
      hq_address_line2: formValues.hq_address_line2 || null,
      hq_address_city: formValues.hq_address_city,
      hq_address_region_id: formValues.hq_address_region_id,
      hq_address_post_code: formValues.hq_address_post_code,
      remittance_address_line1: formValues.remittance_address_line1 || null,
      remittance_address_line2: formValues.remittance_address_line2 || null,
      remittance_address_city: formValues.remittance_address_city || null,
      remittance_address_region_id: formValues.remittance_address_region_id || null,
      remittance_address_post_code: formValues.remittance_address_post_code || null,
      programs: formValues.programs,
    };

    try {
      setIsCreating(true);
      const client = await ClientService.create(newClient);
      toast.success(toastMessages.createClient.success);
      navigate(`/clients/${client.id}`);
    } catch (err) {
      const apiError = err as ApiError;

      if (apiError.body?.errors) {
        const errorItems = apiError.body.errors as ApiErrorItem[];

        const errors = { ...formErrors };

        errorItems.forEach((error) => {
          errors[error.field] = capitalize(error.message);
        });

        setFormErrors(errors);
      } else {
        globalStore.handleApiError(apiError, toastMessages.createClient.error);
      }
    } finally {
      setIsCreating(false);
    }
  };

  /* Render */
  return (
    <FormPage
      title="Add Client"
      breadcrumbNav={
        <BreadcrumbNav previousPages={[{ name: 'Clients', link: '/clients' }]} currentPageName={'Add Client'} />
      }
      isLoading={isLoading}
      isDetailPage
    >
      <FormCard
        title="Client Details"
        icon={<CircleInformation size="24px" color="brand" />}
        isLoading={isLoading || isCreating}
        onSubmit={createClient}
      >
        <ClientGeneralInformation
          updateFormValue={updateFormValue}
          formValues={formValues}
          formErrors={formErrors}
          onSubmit={createClient}
          isCanada={isCanada}
        />

        <Line margin="1rem" />

        <Box direction={isMobile ? 'column' : 'row'}>
          <Box width={isMobile ? '100%' : '50%'}>
            <ClientCorporateInformation
              updateFormValue={updateFormValue}
              formValues={formValues}
              formErrors={formErrors}
              onSubmit={createClient}
            />
          </Box>

          {isMobile ? <Line /> : <Line direction="vertical" margin="2rem" />}

          <Box width={isMobile ? '100%' : '50%'}>
            <ClientRemittanceInformation
              updateFormValue={updateFormValue}
              formValues={formValues}
              formErrors={formErrors}
              onSubmit={createClient}
            />
          </Box>
        </Box>

        <Line margin="1rem" />

        <ClientCommissions
          regionId={formValues.hq_address_region_id}
          commissions={formValues.programs}
          setCommissions={(value: any) => updateFormValue('programs', value)}
          formErrors={commissionErrors}
        />
      </FormCard>
    </FormPage>
  );
});
