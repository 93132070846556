{
  "name": "fuse-frontend",
  "version": "1.0.0",
  "private": true,
  "description": "Frontend for the FuSE 2.0 app",
  "scripts": {
    "build": "parcel build public/index.html",
    "dev": "DEBUG=fuse* yarn lint && parcel public/index.html",
    "start": "parcel public/index.html",
    "lint": "eslint",
    "clean": "rm -rf .parcel-cache",
    "test": "playwright test"
  },
  "engines": {
    "node": "^18.16.0"
  },
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "dependencies": {
    "@sentry/react": "^8.26.0",
    "apexcharts": "^3.44.1",
    "dayjs": "^1.11.10",
    "debug": "^4.3.4",
    "grommet": "^2.32.0",
    "grommet-icons": "^4.11.0",
    "jsonwebtoken": "^9.0.0",
    "leaflet": "^1.9.4",
    "mobx": "^6.9.0",
    "mobx-react-lite": "^3.4.3",
    "react": "^18.0.0",
    "react-apexcharts": "^1.4.1",
    "react-dom": "^18.0.0",
    "react-error-boundary": "^4.0.4",
    "react-leaflet": "^4.2.1",
    "react-router-dom": "^6.11.2",
    "react-toastify": "^9.1.2",
    "styled-components": "5.3.10"
  },
  "devDependencies": {
    "@parcel/css": "^1.14.0",
    "@parcel/transformer-svg-react": "^2.10.2",
    "@playwright/test": "^1.45.3",
    "@types/debug": "^4.1.12",
    "@types/jsonwebtoken": "^9.0.2",
    "@types/leaflet": "^1.9.8",
    "@types/node": "20.1.2",
    "@types/react": "^18.0.0",
    "@types/react-dom": "^18.2.4",
    "@typescript-eslint/eslint-plugin": "^5.59.6",
    "@typescript-eslint/parser": "^5.59.6",
    "buffer": "^5.5.0",
    "crypto-browserify": "^3.12.0",
    "eslint": "8.40.0",
    "events": "^3.1.0",
    "parcel": "^2.10.2",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "typescript": "5.0.4",
    "url": "^0.11.0",
    "util": "^0.12.3"
  }
}
