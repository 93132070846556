import { Program, TClientId, TProgramId, TReportingPeriodTypeId } from '.';
import { DateString, FloatString, Nullable } from '../types';

export type TClientProgramId = number;

export interface ClientProgram {
  id: TClientProgramId;
  client_id: TClientId;
  end_reporting_quarter: DateString;
  fixed_kwh_rate: Nullable<FloatString>;
  percent_rate: FloatString;
  program: Program;
  program_id: TProgramId;
  reporting_period_type_id: TReportingPeriodTypeId;
  start_reporting_quarter: DateString;
}
