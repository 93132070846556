import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from './Box';

export type InfoBoxProps = PropsWithChildren & BoxProps;

export const InfoBox: React.FC<InfoBoxProps> = ({ children, pad, gap, background, ...boxProps }) => {
  return (
    <Box
      pad={pad || '1rem'}
      gap={gap || '0.5rem'}
      background={background || 'light-6'}
      border={{ size: 'small', color: 'light-5' }}
      borderRadius="6px"
      direction="row"
      align="center"
      fontFamily="Lato, sans-serif"
      {...boxProps}
    >
      {children}
    </Box>
  );
};
