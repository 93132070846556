import dayjs from 'dayjs';
import { CheckBox, DataTable, DropButton, Pagination, Tip } from 'grommet';
import { Checkmark, CircleAlert, CircleInformation, Filter, SettingsOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ModelAuditLogService, TListModelAuditLogsRequest } from '/src/api';
import {
  AddButton,
  AuditLogEventTypeIcon,
  AuditLogIcon,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  DataTableHeader,
  DataTableItem,
  EntityIcon,
  FiltersDropdown,
  Link,
  LoadingSpinner,
  Text,
} from '/src/components';
import { useGlobalStore } from '/src/context';
import { Equipment, Facility, Model, ModelAuditLog, ModelName, User } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import {
  AuditorType,
  ModelAuditLogEventType,
  ModelAuditLogState,
  SelectOptions,
  TResponseMetadata,
} from '/src/lib/types';
import { camelToSnakeCase, getModelUrl, getQueryParams } from '/src/utils';

export const ModelAuditLogList: React.FC<ModelAuditLogListProps> = observer((props) => {
  /* Props */
  const { title = 'Logs List', showFilters, showSearchInput, hideHeader, ...boxProps } = props;

  /* Context */
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const globalStore = useGlobalStore();

  const defaultFilters = useRef({
    model_name: undefined,
    action_taken_by: AuditorType.ExternalUser,
    model_audit_log_state_id: ModelAuditLogState.Unresolved,
  });

  /* State */
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false);
  const [isFiltersMenuHovered, setIsFiltersMenuHovered] = useState(false);
  const [limit, setLimit] = useState(20);

  const [modelAuditLogs, setModelAuditLogs] = useState<ModelAuditLog[]>();
  const [metadata, setMetadata] = useState<TResponseMetadata>();
  const [request, setRequest] = useState<TListModelAuditLogsRequest>({ ...defaultFilters.current });
  const [filters, setFilters] = useState<Record<string, any>>(defaultFilters.current);

  const [actionTakenBy, setActionTakenBy] = useState<AuditorType>(defaultFilters.current.action_taken_by);
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  /** Computed **/
  const currentPage = queryParams.page ? parseInt(queryParams.page) : 1;
  const hasModelAuditLogs = !!modelAuditLogs?.length;
  const hasSelectedRows = !!Object.values(selectedRows).find((row) => !!row);

  const statusFilterOptions = useMemo(() => {
    const options: SelectOptions<number> = [{ label: 'All', value: 0 }];

    if (actionTakenBy !== AuditorType.System && actionTakenBy !== AuditorType.InternalUser) {
      options.push({ label: 'Unresolved', value: 1 });
      options.push({ label: 'Resolved', value: 2 });
    }

    return options;
  }, [actionTakenBy]);

  const fetchModelAuditLogs = async (page?: number) => {
    try {
      const response = await ModelAuditLogService.list({ ...request, limit, page: page || request.page });
      const { meta, data } = response;

      const filteredData = data?.filter((log) => Object.values(ModelName).includes(log.model_name as ModelName));

      setModelAuditLogs(filteredData);
      setMetadata(meta);
      setActionTakenBy(request.action_taken_by || defaultFilters.current.action_taken_by);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listAuditLogs.error);
    } finally {
      setIsLoading(false);
      setIsFiltering(false);
      setIsSearching(false);
    }
  };

  const getModelLabel = (log: ModelAuditLog) => {
    const isDelete = log.model_audit_log_event_type.name === ModelAuditLogEventType.Delete;
    const modelData = isDelete ? log.old_data : log.model;
    if (!modelData) return '';

    let model = {} as typeof modelData;

    if (isDelete) {
      Object.keys(modelData).forEach((key) => {
        const val = (modelData as Record<string, string | number>)[key];
        (model as Record<string, string | number>)[camelToSnakeCase(key)] = val;
      });
    } else {
      model = modelData;
    }

    switch (log.model_name) {
      case ModelName.Client:
        return model.name ?? '';
      case ModelName.Facility:
        return (model as Facility).address_line1 ?? '';
      case ModelName.Equipment:
        return `${(model as Equipment).manufacturer ?? ''}: ${(model as Equipment).serial_number ?? ''}`;
      case ModelName.EquipmentUsage:
        return 'Equipment Usage';
      case ModelName.User:
        return (model as User).name ?? '';
    }
  };

  const applyFilters = () => {
    const _filters = { ...filters };
    const req = { ...request };
    req.model_id = filters.model_id || undefined;
    req.user_id = filters.user_id || undefined;
    req.action_taken_by = filters.action_taken_by || undefined;

    if (filters.model_name) req['model_name[]'] = filters.model_name || undefined;

    if (req.action_taken_by === AuditorType.System || req.action_taken_by === AuditorType.InternalUser) {
      req.model_audit_log_state_id = undefined;
      _filters.model_audit_log_state_id = 0;
      setFilters(_filters);
    } else {
      req.model_audit_log_state_id = filters.model_audit_log_state_id || undefined;
    }

    setRequest(req);
    setIsFiltering(true);
    setIsFiltersMenuOpen(false);
  };

  const clearFilters = () => {
    setRequest({ page: 1, ...defaultFilters.current });
    setFilters(defaultFilters.current);
    setIsFiltering(true);
    setIsFiltersMenuOpen(false);
  };

  const resolveSelectedRows = async () => {
    try {
      setIsUpdating(true);

      const promises = Object.keys(selectedRows)
        .filter((logId) => !!selectedRows[parseInt(logId)])
        .map((logId) =>
          ModelAuditLogService.updateState({
            model_audit_log_id: parseInt(logId),
            model_audit_log_state_id: ModelAuditLogState.Resolved,
          })
        );

      await Promise.all(promises);
      toast.success(toastMessages.resolveAuditLogs.success);
      setAllRowsSelected(false);
      setSelectedRows({});
      await fetchModelAuditLogs();
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.resolveAuditLogs.error);
    } finally {
      setIsUpdating(false);
    }
  };

  /* Effects */
  useEffect(() => {
    if (isSearching || isFiltering) {
      fetchModelAuditLogs();
    }
  }, [isSearching, isFiltering]);

  useEffect(() => {
    if (!modelAuditLogs) {
      if (Object.keys(filters).length) applyFilters();
      fetchModelAuditLogs(currentPage);
    }
  }, [modelAuditLogs, currentPage, filters]);

  useEffect(() => {
    if (allRowsSelected) {
      const selected: Record<number, boolean> = {};
      modelAuditLogs?.forEach((log) => (selected[log.id] = true));
      setSelectedRows(selected);
    } else {
      setSelectedRows({});
    }
  }, [allRowsSelected, modelAuditLogs]);

  useEffect(() => {
    if (!isLoading && metadata && currentPage !== metadata.current_page) {
      setIsLoading(true);
      fetchModelAuditLogs(currentPage);
    }
  }, [isLoading, metadata, currentPage]);

  /* Render */
  const renderClientColumn = (log: ModelAuditLog) => {
    const isDelete = log.model_audit_log_event_type.name === ModelAuditLogEventType.Delete;
    const modelData = log.model ?? ({} as Model);
    const client =
      log.model_name === ModelName.User ? (modelData as User).clients[0] : (modelData as Facility | Equipment)?.client;

    return (
      <Box overflow={{ horizontal: 'hidden' }}>
        {client &&
          (!isDelete ? (
            <Link to={`/clients/${client.id}`}>
              <Text fontFamily="Lato, sans-serif" color="accent-1">
                {client.name}
              </Text>
            </Link>
          ) : (
            <Text fontFamily="Lato, sans-serif" color="accent-1">
              {client.name}
            </Text>
          ))}
        {!client && '—'}
      </Box>
    );
  };

  const renderModelNameColumn = (log: ModelAuditLog) => {
    const isEquipmentUsage = log.model_name === ModelName.EquipmentUsage;

    return (
      <DataTableItem icon={<EntityIcon entityName={log.model_name} size="18" color="accent-1" />}>
        <Box direction="row" align="center" gap="xsmall">
          {!isEquipmentUsage ? (
            <Link to={getModelUrl(log.model, log.model_name as ModelName)}>
              <Text fontFamily="Lato, sans-serif" color="accent-1">
                {getModelLabel(log)}
              </Text>
            </Link>
          ) : (
            <Text fontFamily="Lato, sans-serif">Equipment Usage</Text>
          )}
        </Box>
      </DataTableItem>
    );
  };

  return (
    <Box fill="horizontal">
      <Box direction="row" gap="medium">
        <Card {...boxProps} flex>
          {!hideHeader && (
            <CardHeader title={title || 'Change Log List'} icon={<AuditLogIcon />}>
              <Box direction="row" gap="medium">
                <AddButton
                  label="Resolve Selected Logs"
                  onClick={() => resolveSelectedRows()}
                  disabled={!hasSelectedRows}
                />
                <DropButton
                  plain
                  icon={<Filter color={isFiltersMenuHovered || isFiltersMenuOpen ? 'brand' : undefined} />}
                  open={isFiltersMenuOpen}
                  onOpen={() => setIsFiltersMenuOpen(true)}
                  onClose={() => setIsFiltersMenuOpen(false)}
                  onMouseOver={() => setIsFiltersMenuHovered(true)}
                  onMouseOut={() => setIsFiltersMenuHovered(false)}
                  tip="Filter Logs"
                  dropAlign={{ right: 'right', top: 'bottom' }}
                  dropContent={
                    <FiltersDropdown
                      isFiltering={isFiltering}
                      onSubmit={() => applyFilters()}
                      onClear={() => clearFilters()}
                      filters={[
                        {
                          label: 'Status',
                          value: filters.model_audit_log_state_id,
                          setValue: (value) => setFilters({ ...filters, model_audit_log_state_id: value }),
                          options: statusFilterOptions,
                          disabled:
                            filters.action_taken_by === AuditorType.System ||
                            filters.action_taken_by === AuditorType.InternalUser,
                        },
                        {
                          label: 'Model Type',
                          value: filters.model_name,
                          setValue: (value) => setFilters({ ...filters, model_name: value }),
                          options: [
                            { label: 'All', value: '' },
                            { label: 'Client', value: ModelName.Client },
                            { label: 'Facility', value: ModelName.Facility },
                            { label: 'Equipment', value: ModelName.Equipment },
                            { label: 'Equipment Usage', value: ModelName.EquipmentUsage },
                          ],
                        },
                        {
                          label: 'Action Taken By',
                          value: filters.action_taken_by,
                          setValue: (value) =>
                            setFilters({
                              ...filters,
                              action_taken_by: value,
                              model_audit_log_state_id:
                                value !== AuditorType.ExternalUser
                                  ? 0
                                  : filters.model_audit_log_state_id === 0
                                    ? ModelAuditLogState.Unresolved
                                    : filters.model_audit_log_state_id,
                            }),
                          options: [
                            { label: 'All', value: '' },
                            { label: 'System', value: AuditorType.System },
                            { label: 'Internal User', value: AuditorType.InternalUser },
                            { label: 'External User', value: AuditorType.ExternalUser },
                          ],
                        },
                      ]}
                    />
                  }
                />
              </Box>
            </CardHeader>
          )}
          <CardBody pad="none" gap="none">
            <Box elevation="small">
              {(isLoading || isSearching || isFiltering || isUpdating) && <LoadingSpinner />}
              {!isLoading && !isSearching && !isFiltering && !isUpdating && (
                <DataTable
                  pad={{ horizontal: '1.5rem' }}
                  columns={[
                    {
                      property: 'id',
                      primary: true,
                      size: '7%',
                      sortable: false,
                      header: (
                        <DataTableHeader>
                          <CheckBox
                            name="all_rows_selected"
                            checked={allRowsSelected}
                            onChange={() => setAllRowsSelected(!allRowsSelected)}
                          />
                        </DataTableHeader>
                      ),
                      render: (modelAuditLog) => (
                        <Box direction="row" gap="medium">
                          <CheckBox
                            name={modelAuditLog.id?.toString()}
                            checked={!!selectedRows[modelAuditLog.id]}
                            onChange={() => {
                              setSelectedRows({ ...selectedRows, [modelAuditLog.id]: !selectedRows[modelAuditLog.id] });
                            }}
                          />
                          <Box pad={{ vertical: '1.75rem' }} align="start">
                            <Link to={`/change-logs/${modelAuditLog.id}`} color="accent-1">
                              <Box justify="center">
                                <Tip content="View Log Details">
                                  <SettingsOption size="18px" color="accent-1" />
                                </Tip>
                              </Box>
                            </Link>
                          </Box>
                        </Box>
                      ),
                    },
                    {
                      property: 'model_audit_log_event_type',
                      // size: '21%',
                      size: '11%',
                      header: <DataTableHeader title="EVENT" />,
                      render: (modelAuditLog) => (
                        <DataTableItem
                          value={modelAuditLog.model_audit_log_event_type.name}
                          icon={
                            <AuditLogEventTypeIcon
                              auditLogEventTypeName={modelAuditLog.model_audit_log_event_type.name}
                              size="14px"
                            />
                          }
                        />
                      ),
                    },
                    {
                      property: 'model.client_id',
                      size: '19%',
                      header: <DataTableHeader title="CLIENT" />,
                      render: renderClientColumn,
                    },
                    {
                      property: 'model.id',
                      // size: '20%',
                      header: <DataTableHeader title="MODEL NAME" />,
                      render: renderModelNameColumn,
                    },
                    {
                      property: 'user.id',
                      // size: '13%',
                      header: <DataTableHeader title="USER" />,
                      render: (modelAuditLog) => (
                        <DataTableItem>
                          {modelAuditLog.user ? (
                            <Box>
                              <Link to={`/users/${modelAuditLog.user?.id}`} textDecoration="none">
                                <Text
                                  color="accent-1"
                                  size="medium"
                                  weight={700}
                                  fontFamily="Lato, sans-serif"
                                  lineHeight="1rem"
                                >
                                  {modelAuditLog.user?.name}
                                </Text>
                              </Link>
                              <Text size="small" fontFamily="Lato, sans-serif" lineHeight="1.25rem">
                                {modelAuditLog.user?.email}
                              </Text>
                              <Text size="small" fontFamily="Lato, sans-serif" lineHeight="1rem">
                                {modelAuditLog.user?.phone}
                              </Text>
                            </Box>
                          ) : (
                            <Text>—</Text>
                          )}
                        </DataTableItem>
                      ),
                    },
                    {
                      property: 'created_at',
                      // size: '12%',
                      header: <DataTableHeader title="DATE" />,
                      render: (modelAuditLog) => (
                        <DataTableItem value={dayjs(modelAuditLog.created_at).format('MM/DD/YYYY')} />
                      ),
                    },
                    {
                      property: 'is_resolved',
                      // size: '12%',
                      header: (
                        <Box align="center" justify="center" direction="row" gap="xsmall">
                          <DataTableHeader title="STATUS" />
                          <Tip content="Only logs generated by external users have a resolution status.">
                            <CircleInformation size="14px" />
                          </Tip>
                        </Box>
                      ),
                      render: (modelAuditLog: ModelAuditLog) => (
                        <DataTableItem
                          value={
                            modelAuditLog.model_audit_log_state_id === null
                              ? '—'
                              : modelAuditLog.model_audit_log_state_id === ModelAuditLogState.Resolved
                                ? 'Resolved'
                                : 'Unresolved'
                          }
                          icon={
                            modelAuditLog.model_audit_log_state_id ===
                            null ? undefined : modelAuditLog.model_audit_log_state_id ===
                              ModelAuditLogState.Resolved ? (
                                  <Checkmark size="16px" />
                                ) : (
                                  <CircleAlert size="16px" />
                                )
                          }
                        />
                      ),
                    },
                  ]}
                  data={modelAuditLogs}
                  sortable
                  background={['light-6', 'white']}
                  border={{ color: 'light-2', side: 'bottom', size: 'small' }}
                />
              )}
              {!hasModelAuditLogs && !isLoading && !isSearching && (
                <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                  <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                    No audit logs found.
                  </Text>
                </Box>
              )}
            </Box>
            {hasModelAuditLogs && !!metadata && !!currentPage && (
              <Box pad="1.5rem" flex="grow">
                <Pagination
                  alignSelf="center"
                  size="small"
                  page={currentPage}
                  step={metadata.per_page}
                  numberItems={metadata.total}
                  onChange={({ page }) => navigate(`${location.pathname}?page=${page}`)}
                />
              </Box>
            )}
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
});

export type ModelAuditLogListProps = BoxProps & {
  title?: string;
  showFilters?: boolean;
  hideHeader?: boolean;
};
